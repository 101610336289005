import { RouteInfo } from "./sidebar.metadata";
export const ROUTES: RouteInfo[] = [
  {
    path: "/admin/dashboard/main",
    title: "Dashboard",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "dashboard",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "badge bg-blue sidebar-badge float-end",
    role: ["SuperAdministrator"],
    submenu: [],
  }, 
  {
    path: "/admin/user",
    title: "Distributor & Retail",
    moduleName: "distributorretail",
    iconType: "material-icons-two-tone",
    icon: "groups",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "badge bg-blue sidebar-badge float-end",
    role: ["SuperAdministrator"],
    submenu: [],
  },
  /* {
    path: "/admin/customerlist",
    title: "Customer",
    moduleName: "customer",
    iconType: "material-icons-two-tone",
    icon: "people",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "badge bg-blue sidebar-badge float-end",
    role: ["SuperAdministrator"],
    submenu: [],
  }, 
  {
    path: "/admin/vendorlist",
    title: "Vendor",
    moduleName: "vendor",
    iconType: "material-icons-two-tone",
    icon: "store",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "badge bg-blue sidebar-badge float-end",
    role: ["SuperAdministrator"],
    submenu: [],
  },  */
  {
    path: "",
    title: "Product",
    moduleName: "product",
    iconType: "material-icons-two-tone",
    icon: "camera",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["SuperAdministrator"],
    submenu: [
      {
        path: "/admin/brandlist",
        title: "Brands",
        moduleName: "product",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "#",
        title: "Optical Lens Master",
        moduleName: "product",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/framelist",
        title: "Frame Master",
        moduleName: "product",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/contactlenslist",
        title: "Contact Lens Master",
        moduleName: "product",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/otheraccessorieslist",
        title: "Other Accessories",
        moduleName: "product",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/clbatchdetail",
        title: "CL Batch Details",
        moduleName: "product",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  // Distributor Module
  {
    path: "/admin/dashboard/distributor-dashboard",
    title: "Dashboard",
    moduleName: "distributor",
    iconType: "material-icons-two-tone",
    icon: "dashboard",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "badge bg-blue sidebar-badge float-end",
    role: ["Distributor Admin"],
    submenu: [],
  },
  {
    path: "",
    title: "Settings",
    moduleName: "settings",
    iconType: "material-icons-two-tone",
    icon: "settings",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Distributor Admin"],
    submenu: [
      {
        path: "/admin/pool-creation",
        title: "Pool Creation",
        moduleName: "settings",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/location-settings",
        title: "Location Settings",
        moduleName: "settings",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/my-users",
        title: "Add Users",
        moduleName: "settings",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/settings",
        title: "General Settings",
        moduleName: "settings",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "/admin/group",
    title: "Customer Group",
    moduleName: "group",
    iconType: "material-icons-two-tone",
    icon: "groups",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "badge bg-blue sidebar-badge float-end",
    role: ["Distributor Admin"],
    submenu: [],
  },
  {
    path: "/admin/customerlist",
    title: "Customer",
    moduleName: "customer",
    iconType: "material-icons-two-tone",
    icon: "people",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "badge bg-blue sidebar-badge float-end",
    role: ["Distributor Admin"],
    submenu: [],
  }, 
  {
    path: "/admin/vendorlist",
    title: "Vendor",
    moduleName: "vendor",
    iconType: "material-icons-two-tone",
    icon: "store",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "badge bg-blue sidebar-badge float-end",
    role: ["Distributor Admin"],
    submenu: [],
  },
  /* {
    path: "",
    title: "Reports",
    moduleName: "reports",
    iconType: "material-icons-two-tone",
    icon: "flag",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "badge bg-blue sidebar-badge float-end",
    role: ["Distributor Admin"],
    submenu: [],
  }, */
  {
    path: "",
    title: "Reports",
    moduleName: "reports",
    iconType: "material-icons-two-tone",
    icon: "flag",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Distributor Admin"],
    submenu: [
      {
        path: "/reports/stock-report",
        title: "Download Stock Report",
        moduleName: "reports",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/reports/search-report",
        title: "Search Stock Report",
        moduleName: "reports",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/reports/custom-report",
        title: "Custom Reports",
        moduleName: "reports",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/reports/other-report",
        title: "Other Reports",
        moduleName: "reports",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/reports/stock-lens-report",
        title: "Stock Lens Inventory Report",
        moduleName: "reports",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      }
    ],
  },
  {
    path: "/admin/orderlist",
    title: "Order",
    moduleName: "order",
    iconType: "material-icons-two-tone",
    icon: "grading",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "badge bg-blue sidebar-badge float-end",
    role: ["Distributor Admin"],
    submenu: [],
  },
  {
    path: "",
    title: "GRN",
    moduleName: "grn",
    iconType: "material-icons-two-tone",
    icon: "store",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Distributor Admin"],
    submenu: [
      {
        path: "/admin/grnlist",
        title: "GRN From Order",
        moduleName: "grn",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/dc-grnlist",
        title: "GRN From DC",
        moduleName: "grn",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/allocatestovkol",
        title: "Allocate Stock for OL",
        moduleName: "grn",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      }
    ],
  },
  {
    path: "/admin/purchasereturnlist",
    title: "Purchase Return",
    moduleName: "purchasereturn",
    iconType: "material-icons-two-tone",
    icon: "assignment_return",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "badge bg-blue sidebar-badge float-end",
    role: ["Distributor Admin"],
    submenu: [],
  },
  {
    path: "/admin/salereturnlist",
    title: "Sale Return",
    moduleName: "salereturn",
    iconType: "material-icons-two-tone",
    icon: "sell",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "badge bg-blue sidebar-badge float-end",
    role: ["Distributor Admin"],
    submenu: [],
  }, 
  {
    path: "",
    title: "Invoice",
    moduleName: "invoice",
    iconType: "material-icons-two-tone",
    icon: "receipt",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Distributor Admin"],
    submenu: [
      {
        path: "/admin/fromorder",
        title: "From Order",
        moduleName: "invoice",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/dc-invoice",
        title: "DC Invoice",
        moduleName: "invoice",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      }
    ],
  },
  {
    path: "",
    title: "Product",
    moduleName: "product",
    iconType: "material-icons-two-tone",
    icon: "camera",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Distributor Admin"],
    submenu: [
      {
        path: "/admin/brandlist",
        title: "Brands",
        moduleName: "product",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "#",
        title: "Optical Lens Master",
        moduleName: "product",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/clbatchdetail",
        title: "CL Batch Details",
        moduleName: "product",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/framelist",
        title: "Frame Master",
        moduleName: "product",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/contactlenslist",
        title: "Contact Lens Master",
        moduleName: "product",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/accessorieslist",
        title: "Accessories Master",
        moduleName: "product",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  // Retail Module
  {
    path: "/admin/dashboard/retail-dashboard",
    title: "Dashboard",
    moduleName: "distributor",
    iconType: "material-icons-two-tone",
    icon: "dashboard",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "badge bg-blue sidebar-badge float-end",
    role: ["Retail Admin"],
    submenu: [],
  },
  {
    path: "/admin/settings",
    title: "Settings",
    moduleName: "settings",
    iconType: "material-icons-two-tone",
    icon: "settings",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "badge bg-blue sidebar-badge float-end",
    role: ["Retail Admin"],
    submenu: [],
  },
  {
    path: "/admin/group",
    title: "Customer Group",
    moduleName: "group",
    iconType: "material-icons-two-tone",
    icon: "groups",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "badge bg-blue sidebar-badge float-end",
    role: ["Retail Admin"],
    submenu: [],
  },
  {
    path: "/admin/customerlist",
    title: "Customer",
    moduleName: "customer",
    iconType: "material-icons-two-tone",
    icon: "people",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "badge bg-blue sidebar-badge float-end",
    role: ["Retail Admin"],
    submenu: [],
  }, 
  {
    path: "/admin/vendorlist",
    title: "Vendor",
    moduleName: "vendor",
    iconType: "material-icons-two-tone",
    icon: "store",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "badge bg-blue sidebar-badge float-end",
    role: ["Retail Admin"],
    submenu: [],
  }, 
  {
    path: "",
    title: "Product",
    moduleName: "product",
    iconType: "material-icons-two-tone",
    icon: "camera",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Retail Admin"],
    submenu: [
      {
        path: "/admin/brandlist",
        title: "Brands",
        moduleName: "product",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "#",
        title: "Optical Lens Master",
        moduleName: "product",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/framelist",
        title: "Frame Master",
        moduleName: "product",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/contactlenslist",
        title: "Contact Lens Master",
        moduleName: "product",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/otheraccessorieslist",
        title: "Accessories Master",
        moduleName: "product",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  }
];
